<script setup>
import { inject, ref, toRef, computed, onMounted, nextTick, watch } from 'vue';
import { doc, getDoc } from "firebase/firestore";
import axios from "axios"

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import { getEnvironment } from "@/utils/env";

const { ENV, isDev } = getEnvironment();

const appContext = inject('app-context')
const firestore = inject('firestore')


console.log("_template Loaded");

const props = defineProps({
  isDebug: Boolean,
  resourceId: String,
  liff: Object,
  liffId: String,
  isLiffReady: Boolean,
  toggleLoading: Function
});

const { resourceId, liff, liffId, isLiffReady, toggleLoading } = props

const isDebugRef = toRef(() => props.isDebug)
const isDebug_ = ref(isDebugRef.value)

watch(isDebugRef, async (newDebugState) => {
  isDebug_.value = newDebugState;
  
  console.info(isDebug__.value ? "Debugger on" : "Debugger off");
})

console.log("Environment", ENV);

const GMTcontainerId = ENV === "development" ? "GTM-PPK6989S" : "GTM-P3S85HS"

const analytics = Analytics({
  app: `line-liff-${liffId}`,
  plugins: [
    googleTagManager({
      containerId: GMTcontainerId,
    })
  ]
})

if (!isLiffReady) {
  console.log("Liff is", isLiffReady ? "Ready" : "Not Ready"); 
}

// console.log("Props", props)
// console.log("LIFF", liffId, liff)

const url = new URL(window.location.href)
// const params = new Proxy(
//   new URLSearchParams(window.location.search),
//   {
//     get: (searchParams, prop) => searchParams.get(prop),
//   }
// )
// // const myParam = urlParams.get('myParam');
// console.log("Check user_id", searchParams.has("user_id"), searchParams.get("user_id"))
// console.log("Check project_ids", searchParams.has("project_ids"), searchParams.get("project_ids"))

  
function getSearchParams() {

  if (!isLiffReady) {
    return {
      "user_id": "0",
      "project_ids": []
    }
  }

  const searchParams = new URLSearchParams(window.location.search);
  const project_ids = searchParams.get("project_ids")

  return {
    "build": searchParams.get("build"),
    "user_id": searchParams.get("user_id"),
    "project_ids": project_ids ? project_ids.split(",") : [],
    "channel_id": searchParams.get("channel_id"),
    "debug": searchParams.get("debug")
  }
}

const params = getSearchParams()
// const projectListX = ref([
  // {
  //     label: 'โครงการที่คุณสอบถาม',
  //     code: 'INTERESTED',
  //     items: [
  //         { label: 'Berlin', value: 'Berlin' }
  //     ]
  // },
  // {
  //     label: 'โครงการอื่นๆ',
  //     code: 'OTHERS',
  //     items: [
  //         { label: 'Berlin', value: 'Berlin' }
  //     ]
  // },
// ]);

const context = liff.getContext();
const { userId: lineUserId } = context;

console.log("LINE User ID: ", lineUserId);

// Page States
const isLoading = ref(false)
const isSuccess = ref(false)
const isError = ref(false)
const isSubmitting = ref(false)

const projectList = ref([]);
const selectedProject = ref({
  projectName: ""
});

// Form States
// const firstname = ref(isDev ? "Jimmy" : "")
// const lastname = ref(isDev ? "Israkhan" : "")
// const tel = ref(isDev ? "20240115" : "")
// const email = ref(isDev ? "jimmy@cath.com" : "")
const firstname = ref("")
const lastname = ref("")
const tel = ref("")
const email = ref("")

if (isDebug_.value) {
  // console.log("Context:", context);
  console.log("OS: ", liff.getOS())

  console.log(window.location.href, url)
  console.log(window.location.search)
  console.log("Params", params)
  console.log("Project List", projectList)
}

async function getProjectListInFirestore() {
  const docRef = doc(firestore, "brand_project", resourceId);

  const docSnap = await getDoc(docRef)

  if (!docSnap.exists()) {
    return []
  }
  const { project_list: projectListString } = docSnap.data()
  const projectList = JSON.parse(projectListString)
  return projectList
}

function getProjectById(projectList, projectId) {
  if (typeof projectList.value[0].items.length == "undefined" || projectList.value[0].items.length < 1) {
    return null
  }

  const project = projectList.value[0].items.find((project) => project.projectId == projectId)

  return project || null

}

function formatProjectList(projectList) {
  return projectList.value.map((project) => {
    return {
      ...project,
      optionLabel: project.projectName,
      optionValue: project
    }
  })
}

function filterProjectList(projectList, selectedProjectIds) {
  const interestedProjectList = []
  const rest = []
  

  for (let project of projectList) {
    if (selectedProjectIds.indexOf(project.projectId) !== -1) {
      interestedProjectList.push(project)
    } else {
      rest.push(project)
    }
  }
  
  console.log("Filtered Project List", interestedProjectList, rest, projectList)

  return [interestedProjectList, rest]
}

const projectListOptions = computed(() => {
  return formatProjectList(projectList)
})

const isValidForm = computed(() => {
  return (selectedProject.value.projectName
    && firstname.value !== ""
    && lastname.value !== ""
    && tel.value !== ""
    && email.value !== "")
});

onMounted(async () => {
  
  const receivedProjectList = await getProjectListInFirestore()
  if (receivedProjectList.length > 0) {
    // const mappedProjectList = formatProjectList(receivedProjectList)
    const [interestedProjectList, restProjectList] = filterProjectList(receivedProjectList, params.project_ids)
  
    if (interestedProjectList.length > 0) {

      projectList.value = [
        {
            label: 'โครงการที่สอบถาม',
            code: 'INTERESTED',
            items: interestedProjectList
        },
        {
            label: 'โครงการอื่นๆ',
            code: 'OTHERS',
            items: restProjectList
        }
      ]
    } else {
      projectList.value = [
        {
            label: 'โครงการต่างๆ',
            code: 'PROJECTS',
            items: receivedProjectList
        }
      ]
    }
  
    const project = getProjectById(projectList, params.project_ids[0])
    console.log("selectedProject", project)
    // Object.assign(selectedProject, project)
    if (project) {

      selectedProject.value = project
      console.log("New Selected Project", project)
    }
    // selectedProject.optionValue.value = project.optionValue
    // selectedProject.optionLabel.value = "X"
  }

  toggleLoading();
})

const mockDropdownOptions = [
  {
    optionLabel: "1",
    optionValue: 1
  },
  {
    optionLabel: "2",
    optionValue: 2
  }
]


function validateData(data) {
  const { firstname, lastname, tel, email, project_id, project_code } = data
  console.log(data)
  
  if (
    (!firstname || firstname == "")
    || (!lastname || lastname == "")
    || ((!tel || tel == "") || (!email || email == ""))
    || (!project_id || project_id == "")
    || (!project_code || project_code == "")
  ) {
    throw ("Data not valid")
  }

  return data
}

function createPayload(data) {

  const { firstname, lastname, tel, email, project_id, project_code } = validateData(data)
  try {

    const payload = {
      "Status": "OPEN",
      "FirstName": firstname,
      "LastName": lastname,
      "MobilePhone": tel,
      "Email": email,
      "LeadSource": "Line Generate by AI",
      "project_id__c": project_id,
      "Project_code__c": project_code,
      "Purpose__c": "ลงทะเบียน",
      "purpose_id__c": 3,
      "Lead_Type2__c": "Lead",
      "Contact_Source__c": "Line Generate by AI",
      "UTM_Channel__c": "",
      "UTM_Source__c": "",
      "User_Agent__c": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36",
      "Browser__c": "Chrome",
      "URL_Referrer__c": "",
      "Source_URL__c": "",
      "GACLIENTID__c": "",
      "GATRACKID__c": "UA-116693252-2",
      "GAUSERID__c": "",
      "Event_Name__c": ""
    }

    console.log(payload);
  
    return payload
  } catch (e) {
    console.error(e)
  }

}

async function submitCreateLead() {
  try {
    isLoading.value = true
    isSubmitting.value = true
    await nextTick()

    const { projectId, projectCodeIt } = selectedProject.value
    // console.log("Selected Project", selectedProject.value)
    
    const payload = createPayload({
      firstname: firstname.value,
      lastname: lastname.value,
      tel: tel.value,
      email: email.value,
      project_id: projectId,
      project_code: projectCodeIt 
    })
    const { channel_id } = params;
    
    await makeRequestCreateLead(payload)
    isSuccess.value = true
    
    console.log("Sending Success message to ", lineUserId)
    await sendSuccessOnSubmitMessageViaLineMessagingAPI({ lineUserId, channel_id })
    
    analytics.track('salesforce-lead-submit', {
      environment: ENV
    })
  } catch (e) {
    console.error("Error on summit", e)
    isError.value = true

  } finally {
    isLoading.value = false
    isSubmitting.value = false
    console.log("Done making request to SF")
    
    await nextTick()
    
    if (isDebug_.value === false) {
      await new Promise(resolve => setTimeout(() => {
        liff.closeWindow();
        resolve();
      }, 3000));
    }
  }
}

function getEnvConfig(env) {
  const CONFIG = {
    "development": {
      bearerToken: "00D0l0000003dA9!ASAAQOb.h6O2NVrqdpNgt8U8LyOxkv.c5OgXrY80ZmnrohupyukPcYS.LJmxBihpptvoB1E7yjGL8lGEEW9TTIPvhtpxUL7m",
      salesforceLeadCreationApi: "https://sansiri--stg.sandbox.my.salesforce.com/services/apexrest/lead_from_external"
    },
    "staging": {
      bearerToken: "00D0l0000003dA9!ASAAQOb.h6O2NVrqdpNgt8U8LyOxkv.c5OgXrY80ZmnrohupyukPcYS.LJmxBihpptvoB1E7yjGL8lGEEW9TTIPvhtpxUL7m",
      salesforceLeadCreationApi: "https://sansiri--stg.sandbox.my.salesforce.com/services/apexrest/lead_from_external"
    },
    "production": {
      bearerToken: "00D6F000002SliD!ARgAQBjJigbkiOstjt4jKK.CY378wQK.neMXYpyET_1Fr52_77W7luddVBunkD4vLKFhXQfZ8aexQYFfm7IPXfWa.AhmDCZT",
      salesforceLeadCreationApi: "https://sansiri.my.salesforce.com/services/apexrest/lead_from_external"
    }
  }

  return CONFIG[env]
}

async function makeRequestCreateLead(data) {
  const { salesforceLeadCreationApi: url, bearerToken } = getEnvConfig(ENV);
  // Relay Url 
  // const relayUrl: "https://asia-southeast1-int-genai-chatbot-portal.cloudfunctions.net/api/relay/sansiri/salesforce/lead_create",

  console.log("Salesforce Config", url, bearerToken)

  try {
    await axios({
      method: "POST",
      url,
      headers: {
        "Authorization": `Bearer ${bearerToken}`,
      },
      data
    })
    
  } catch (error) {
    throw({ error, function: "makeRequestCreateLead" }) 
  }
}

async function sendSuccessOnSubmitMessageViaLineMessagingAPI(params) {
  const url = "https://asia-southeast1-int-genai-chatbot-portal.cloudfunctions.net/api/relay/line/messaging_api_push"
  console.log("Line Push params", params)
  const { lineUserId, channel_id } = params

  try {
    return await axios({
      method: "POST",
      url,
      data: {
        lineUserId,
        channel_id
      }
    })

  } catch(error) {
    console.error(error);
    throw({ error, function: "sendSuccessOnSubmitMessageViaLineMessagingAPI" })
  }
}

analytics.page();
</script>

<template>
  <main v-if="isLiffReady">
    <div class="success-box-wrapper" v-if="isSuccess">
      
      <div
        class="
          success-box
          flex
          flex-column
          justify-center
          w100
          text-base
          font-light
          text-center
          my-8
        "
        
        style="
          text-align: center;
          color: black;
        "
      >
        <!-- <div>
          <i class="pi pi-check-circle text-4xl text-green-500" style="width: 50px; height: 50px"></i><br>
        </div> -->
        <div>
          ลงทะเบียนสำเร็จ <br>
          ขอบพระคุณสำหรับข้อมูล <br>
          ทางทีมงาน Sansiri จะติดต่อท่าน โดยเร็วที่สุด <br>
        </div>
      </div>
    </div>

    <div v-else class="p-fluid col-12">
        <!-- {{ projectList }} -->
        <div class="liff-form">
        
          <div class="overlay-container" v-if="isLoading">
            <ProgressSpinner class="spinner" strokeWidth="2" 
              animationDuration=".4s" aria-label="Custom ProgressSpinner" />
          </div>
          
          <div class="col-12">
              <div class="card">
                <div class="dropdown-wrapper flex justify-content-center w100">
                  <Dropdown
                    v-model="selectedProject"
                    :options="projectList"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionLabel="projectName" 
                    :placeholder="
                      selectedProject.projectName
                      || 'กรุณาเลือกโครงการที่คุณสนใจ'
                    "
                    class="w-full text-xs dropdown" 
                  >
                    <template #optiongroup="slotProps">
                        <div
                          class="
                            flex
                            align-items-center
                            text-center
                            dropdown-item"
                          :class="
                            slotProps.option.code === 'INTERESTED'
                              ? 'interested-item-group' : ''"
                        >
                            <div>{{ slotProps.option.label }}</div>
                        </div>
                    </template>
                  </Dropdown>

                </div>

                <br />

                <div class="mb-2">
                  <span class="p-float-label">
                      <InputText
                        id="firstname"
                        class="form-input"
                        type="text"
                        v-model="firstname"
                        :disabled="!selectedProject.projectName"
                        />
                      <label for="firstname">ชื่อ</label>
                  </span>
                </div>
                <br />

                <div class="mb-1">
                  <span class="p-float-label">
                      <InputText
                        id="lastname"
                        class="form-input"
                        type="text"
                        v-model="lastname"
                        :disabled="!selectedProject.projectName"
                      />
                      <label for="lastname">นามสกุล</label>
                  </span>
                </div>
                <br />
                
                <div class="mb-1">
                  <span class="p-float-label">
                      <InputText
                        id="tel"
                        class="form-input"
                        type="text"
                        v-model="tel"
                        :disabled="!selectedProject.projectName"
                      />
                      <label for="tel">เบอร์โทรศัพท์มือถือ 10 หลัก</label>
                  </span>
                </div>
                <br />

                <div class="mb-1">
                  <span class="p-float-label">
                      <InputText
                        id="email"
                        class="form-input"
                        type="text"
                        v-model="email"
                        :disabled="!selectedProject.projectName"
                      />
                      <label for="email">อีเมล</label>
                  </span>
                </div>
                <br />

                <div class="
                  card
                  text-xs
                  font-light
                  text-black
                  text-center
                  mb-4
                  text-pdpa"
                ><strong>
                  เพื่อให้ท่านทราบวิธีและกระบวนการ
                  ที่เราดำเนินการจัดเก็บข้อมูล
                  วัตถุประสงค์การใช้ข้อมูล<br >
                  สามารถศึกษา
                  แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคลที่
                  www.sansiri.com
                </strong>
                </div>
              </div>

              
              <div class="card flex justify-content-center">
                <Button
                  label="ลงทะเบียน"
                  class="
                    submit-button
                    ga-lead-submit-button
                    mr-2 mb-2
                  "
                  :disabled="!isValidForm || isSubmitting"
                  @click="submitCreateLead"
                />
              </div>
          </div>
        </div>
    </div>
  </main>
  <main v-else>
    <span class="text-black" style="color: black;">
      กรุณาเปิดใช้งานบน Line Mobile Application
    </span>
  </main>
  <!-- <div style="color: red">debugging on template {{ isDebug_ ? "on" : "off" }}</div> -->
</template>

<style scoped lang="scss">
.overlay-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000047;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & .spinner {
    width: 100px;
    height: 100px;
  }
}
</style>
